.Join{
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--blue);
    border-radius: 20%;
    margin: -15px 0;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

/* .right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
} */

/* .email-c{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}  */

/* .email-c>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrey);
}

::placeholder{
    color: var(--lightgrey);
} */

.btn-j{
    background-color: #2b6bda88;
    margin-left: 22rem;
    padding: 1rem 2.5rem;
} 

.btn-j:hover{
    background-color: transparent;
    color: white;
    border: 1px solid #2b6bda88;
}

.contact h2{
    margin-bottom: 8rem;
}

.heading{
        color: white;
        font-weight: bold;
        font-size: 2rem;
        margin-left: 40rem;
    }

.contact>form{
    max-width: 50rem;
    margin-left: 20rem;
    text-align: center;
    margin-bottom: 3rem;
}



.contact form .input-box{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.contact form .input-box input,
.contact form textarea{
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    color: #fff;
    background: #323946;
    /* border-radius: 0.8rem; */
    margin: .7rem 0;
}

.contact form .input-box input{
    width: 49%;
}

.contact form textarea{
    resize: none;
}

.contact form .btn{
    margin-top: 3rem;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    /* .Join{
        flex-direction: column;
        padding: 0;
    } */
    .left-j{
        font-size: x-large ;
        flex-direction: column;
    }
    /* .contact>form{
        max-width: 0;
        margin-left: 0;
        text-align: center;
        margin-bottom: 0;
    }
    .contact form .input-box{
        max-width: 100%;
    } */
    .input{
        width: 5rem;
    }
}