.footer-c {
  position: relative;
}

hr {
  border: 1px solid rgb(90, 89, 89);
}

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}

.social {
  display: flex;
  gap: 4rem;
}

.social > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.logo-f > img {
  width: 10rem;
}

