:root{
  --lightgrey: #D9D9D9;
  --gray: #9c9c9c;
  --blue: #2b6bda88;
  --darkgrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #012132 1.14%, #6597ee88 100.75%);
  --appColor: #000000;
}

.App {
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}

::-webkit-scrollbar{
  display: none;
}

.outline-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover{
  cursor: pointer;
}

.blur{
  background: rgba(0, 106, 255, 0.6);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}