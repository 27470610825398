.hero{
    display: flex;
    justify-content: space-between;
}

.left{
    padding: 3rem;
    padding-top: 3.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right{
    flex: 1;
    position: relative;
    background-color: var(--blue);
}

.ad{
    margin-top: 4rem;
    background-color: #363d42;
    /* border-radius: 4rem; */
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
}

.ad>span{
    z-index: 2;
}

.ad>div{
    position: absolute;
    background-color: var(--blue);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.numbers{
    display: flex;
    gap: 2rem;
}

.numbers>div{
    display: flex;
    flex-direction: column;
}

.numbers>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.numbers>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
}

.buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.buttons>:nth-child(1){
    color: white;
    background-color: var(--blue);
    width: 8rem;
}

.buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--blue);
}

.right>.btn{
    position: absolute;
    right: 3rem;
    top: 4rem;
    color: black;
}

.heart{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkgrey);
    width: fit-content;
    padding: 1rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 11rem;
}

.right>.heart>.heart-img{
    width: 2rem;
}

.heart>:nth-child(2){
    color: var(--grey);
}

.heart>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.first_photo{
    position: absolute;
    top: 8rem;
    right: 9rem;
    width: 30rem;

}

.right>.calories>.calories-img{
    width: 6rem;
    height: 4.2rem;
}

.calories{
    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    position: absolute;
    top: 35rem;
    right: 36rem;
    white-space: nowrap;
}

.calories>div{
display: flex;
flex-direction: column;
justify-content: space-between ;
}

.calories>div>:nth-child(1){
    color: var(--gray);
}

.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.blur-h{
    width: 22rem;
    height: 30rem;
    left: 0;
}

@media screen and (max-width: 768px) {
    .hero{
        flex-direction: column;
    }
    .blur-h{
        width: 14rem;
    }
    .ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        
    }
    
    .text>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .buttons{
        justify-content: center;
    }
    .numbers>div>span:nth-of-type(1){
        font-size: large;
    }
    .numbers>div>span:nth-of-type(2){
        font-size: small;
    }
    .right{
        position: relative;
        background: none;
    }
    .heart{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories>div>:nth-child(2){
        color: white;
        font-size: 1rem;
    }
    .first_photo{
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 6rem;
        align-self: center;
    }
}
